export const abbrs = {
  EST: 'Eastern Standard Time',
  EDT: 'Eastern Daylight Time',
  CST: 'Central Standard Time',
  CDT: 'Central Daylight Time',
  MST: 'Mountain Standard Time',
  MDT: 'Mountain Daylight Time',
  PST: 'Pacific Standard Time',
  PDT: 'Pacific Daylight Time',
  GMT: 'Greenwich Mean Time',
  EAT: 'East Africa Time',
  CET: 'Central European Time',
  WAT: 'West Africa Time',
  CAT: 'Central Africa Time',
  EET: 'Eastern European Time',
  CEST: 'Central European Summer Time',
  SAST: 'South African Standard Time',
  HDT: 'Hawaii-Aleutian Daylight Time',
  AKDT: 'Alaska Daylight Time',
  AST: 'Atlantic Standard Time',
  ADT: 'Atlantic Daylight Time',
  NDT: 'Newfoundland Daylight Time',
  NZST: 'New Zealand Standard Time',
  EEST: 'Eastern European Summer Time',
  IST: 'India Standard Time',
  HKT: 'Hong Kong Time',
  WIB: 'Western Indonesian Time',
  WIT: 'Eastern Indonesian Time',
  IDT: 'Israel Daylight Time',
  PKT: 'Pakistan Standard Time',
  WITA: 'Central Indonesian Time',
  KST: 'Korea Standard Time',
  JST: 'Japan Standard Time',
  WEST: 'Western European Summer Time',
  AEST: 'Australian Eastern Standard Time',
  ACST: 'Australian Central Standard Time',
  AWST: 'Australian Western Standard Time',
  UTC: 'Coordinated Universal Time',
  BST: 'British Summer Time',
  MSK: 'Moscow Standard Time',
  HST: 'Hawaii–Aleutian Standard Time',
  MEST: 'Middle European Summer Time',
  ChST: 'Chamorro Standard Time',
  SST: 'Samoa Standard Time',
  AKST: 'Alaskan Standard Time',
  NST: 'Newfoundland Standard Time',
  AEDT: 'Australian Eastern Daylight Time',
  NZDT: 'New Zealand Daylight Time',
  WET: 'Western European Time',
  MET: 'Middle European Time',
  ACDT: 'Australian Central Daylight Time',

  '-01': 'UTC -1',
  '-02': 'UTC -2',
  '-03': 'UTC -3',
  '-04': 'UTC -4',
  '-05': 'UTC -5',
  '-06': 'UTC -6',
  '-07': 'UTC -7',
  '-08': 'UTC -8',
  '-09': 'UTC -9',
  '-0930': 'UTC -9:30',
  '-10': 'UTC -0',
  '-11': 'UTC -11',
  '-12': 'UTC -12',

  '+00': 'UTC +0',
  '+01': 'UTC +1',
  '+02': 'UTC +2',
  '+03': 'UTC +3',
  '+0330': 'UTC +3:30',
  '+04': 'UTC +4',
  '+0430': 'UTC +4:30',
  '+05': 'UTC +5',
  '+0530': 'UTC +5:30',
  '+0545': 'UTC +5:45',
  '+06': 'UTC +6',
  '+0630': 'UTC +6:30',
  '+07': 'UTC +7',
  '+08': 'UTC +8',
  '+0845': 'UTC +8:45',
  '+09': 'UTC +9',
  '+10': 'UTC +10',
  '+11': 'UTC +11',
  '+12': 'UTC +12',
  '+13': 'UTC +13',
  '+1345': 'UTC +13:45',
  '+14': 'UTC +14',
};
